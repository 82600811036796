<template>
  <div class="text-center">
    <lottie-player
      autoplay
      loop
      mode="normal"
      style="height: 300px"
      src="https://assets2.lottiefiles.com/datafiles/vhvOcuUkH41HdrL/data.json"
    ></lottie-player>
    <h6 class="text-center mt-4 text-muted">{{ message }}</h6>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: 'Não existem registros.'
    }
  }
};
</script>

<style></style>
