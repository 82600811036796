<template>
  <div class="flex-row-fluid col-lg-9" :class="$vuetify.breakpoint.smAndDown ? 'mt-10' : ''">
    <!--begin::Card-->

    <div class="card card-custom">
      <!--begin::Header-->
      <div class="card-header py-3">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">Dados Bancários</h3>
          <span class="text-muted font-weight-bold font-size-sm mt-1">
            Atualize suas informações para recebimento de saques
          </span>
        </div>
        <div class="card-toolbar">
          <span id="disabled-wrapper" class="d-inline-block" tabindex="0">
            <b-button v-b-modal.new-account-bank-modal variant="success" :disabled="listBankAccounts.length >= 3">
              <span class="svg-icon svg-icon-md svg-icon-white">
                <inline-svg src="/media/svg/icons/Navigation/Plus.svg" />
              </span>
              Nova conta bancária
            </b-button>
          </span>
          <b-tooltip v-if="listBankAccounts.length >= 3" variant="danger" target="disabled-wrapper">
            Você tem um depósito pendente, para criar outro é necessario concluir ou cancelar!
          </b-tooltip>
        </div>
      </div>

      <!--begin::Body-->
      <div class="card-body row">
        <!--begin::List Contacts-->
        <div class="col-xl-12">
          <!-- Empty table -->
          <div v-if="listBankAccounts.length <= 0 && !isLoading" class="text-center">
            <EmptyTable message="Ops.. Você não possuí nenhuma conta cadastrada." />
          </div>

          <!-- Busy table -->
          <div v-if="isLoading" class="text-center">
            <BusyTable message="Aguarde enquanto carregamos seus depósitos." />
          </div>

          <div v-if="listBankAccounts.length > 0 && !isLoading">
            <h5 class="font-weight-bold mb-6">Dados bancários cadastrados</h5>
            <div
              v-for="(item, i) in listBankAccounts"
              :key="i"
              class="card card-custom bgi-no-repeat card-info gutter-b bg-light-primary shadow"
              style="
                background-position: right top;
                background-size: 30% auto;
                background-image: url(/media/svg/shapes/abstract-4.svg);
              "
            >
              <div class="card-body">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="mr-5">
                    <span class="font-weight-bold text-info font-size-h2">{{ item.ecosystem_bank.name }}</span>
                    <div class="justify-content-between mt-2 font-size-h6 text-dark-45">
                      <div>
                        Titular:
                        <span class="font-weight-bold">{{ currentCustomer.name }}</span>
                      </div>
                      <div>
                        Agencia:
                        <span class="font-weight-bolder">{{ item.agency_code }}</span>
                      </div>
                      <div>
                        Conta:
                        <span class="font-weight-bold">{{ item.account_number }}-{{ item.account_digit }}</span>
                      </div>
                      <div>
                        Tipo:
                        <span class="font-weight-bold">Conta Corrente</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <a class="btn btn-icon btn-danger btn-lg mr-4" @click="deleteAccount(item.id, i, $event.target)">
                      <i class="fa fa-trash"></i>
                    </a>
                  </div>
                </div>
              </div>
              <!--end::Body-->
            </div>
          </div>
        </div>
        <!--begin::List Contacts-->
      </div>
      <!--end::Body-->
    </div>

    <!-- Add account modal -->
    <b-modal id="new-account-bank-modal" title="Adicionar conta bancária" hide-footer>
      <NewAccountBank />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import NewAccountBank from './NewAccountBank';
import EmptyTable from '@/shared/components/extras/EmptyTable';
import BusyTable from '@/shared/components/extras/BusyTable';

export default {
  components: {
    NewAccountBank,
    EmptyTable,
    BusyTable
  },
  mixins: [validationMixin],
  data() {
    return {
      isLoading: false,

      form: {
        account_type_id: null,
        ecosystem_bank_id: null,
        agency_code: null,
        agency_digit: null,
        account_number: null,
        account_digit: null
      }
    };
  },
  computed: mapGetters(['formBankParams', 'listBankAccounts', 'currentCustomer']),
  async mounted() {
    this.$store.dispatch('SET_BREADCRUMB', [{ title: 'Perfil' }, { title: 'Dados Bancários' }]);

    this.isLoading = true;
    await this.$store.dispatch('GET_BANK_ACCOUNT_LIST');
    this.isLoading = false;
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async deleteAccount(id, index) {
      this.$swal({
        title: 'Você tem certeza excluir essa conta?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Cancelar',
        showLoaderOnConfirm: true,
        confirmButtonColor: '#2e9e01',
        cancelButtonColor: '#cc2323',
        preConfirm: async () => {
          return await this.$store
            .dispatch('DELETE_BANK_ACCOUNT', id)
            .then((response) => {
              return response;
            })
            .catch((error) => {
              this.$swal.showValidationMessage(`Request failed: ${error}`);
            });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      }).then((response) => {
        if (response.isConfirmed) {
          this.$swal({
            title: response.value.message,
            icon: 'success'
          });

          this.listBankAccounts.splice(index, 1);
        }
      });
    }
  },
  validations: {
    form: {
      account_type_id: { required },
      ecosystem_bank_id: { required },
      agency_code: { required },
      agency_digit: { required },
      account_number: { required },
      account_digit: { required }
    }
  }
};
</script>
