<template>
  <!--begin::Form-->
  <b-form ref="form-new-account-bank" class="form" @submit.stop.prevent="onSubmit">
    <!-- Input Cell Phone -->
    <b-form-group label="Tipo:" label-for="type">
      <b-form-select
        v-model="$v.form.account_type_id.$model"
        text-field="name"
        value-field="id"
        :options="formBankParams.account_types"
        class="form-control form-control-solid h-auto py-5 px-6 rounded"
        :state="validateState('account_type_id')"
      >
        <template #first>
          <b-form-select-option :value="null" disabled>-- Selecione o tipo de conta --</b-form-select-option>
        </template>
      </b-form-select>

      <b-form-invalid-feedback>Selecione o tipo de conta</b-form-invalid-feedback>
    </b-form-group>

    <b-form-group label="Selecione o banco:" label-for="type">
      <b-form-select
        v-model="$v.form.ecosystem_bank_id.$model"
        class="form-control form-control-solid h-auto py-5 px-6 rounded"
        :state="validateState('ecosystem_bank_id')"
      >
        <template #first>
          <b-form-select-option :value="null" disabled>-- Selecione o banco --</b-form-select-option>
        </template>
        <option v-for="bank in formBankParams.banks" :key="bank.id" :value="bank.id">
          {{ ('00' + bank.id).slice(-3) }} - {{ bank.name }}
        </option>
      </b-form-select>
      <b-form-invalid-feedback>Selecione o tipo de conta</b-form-invalid-feedback>
    </b-form-group>
    <!-- Input Agency -->
    <b-form-group label="Nome">
      <b-form-input
        :value="currentCustomer.name"
        disabled
        class="form-control disabled form-control-solid h-auto py-5 px-6"
      />
    </b-form-group>

    <!-- Input Agency -->
    <div class="row">
      <div class="col-6">
        <b-form-group label="Agencia">
          <b-form-input
            v-model="$v.form.agency_code.$model"
            type="number"
            class="form-control form-control-solid h-auto py-5 px-6 rounded"
            :state="validateState('agency_code')"
          />
          <b-form-invalid-feedback>O campo é obrigatório.</b-form-invalid-feedback>
        </b-form-group>
      </div>

      <div class="col-6">
        <b-form-group label="Dígito">
          <b-form-input
            v-model="$v.form.agency_digit.$model"
            v-mask="'X'"
            class="form-control form-control-solid h-auto py-5 px-6 rounded"
            :state="validateState('agency_digit')"
          />
          <b-form-invalid-feedback>O campo é obrigatório.</b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>

    <!-- Input Account -->
    <div class="row">
      <div class="col-6">
        <b-form-group label="Conta">
          <b-form-input
            v-model="$v.form.account_number.$model"
            type="number"
            class="form-control form-control-solid h-auto py-5 px-6 rounded"
            :state="validateState('account_number')"
          />
          <b-form-invalid-feedback>O campo é obrigatório.</b-form-invalid-feedback>
        </b-form-group>
      </div>
      <div class="col-6">
        <b-form-group label="Dígito">
          <b-form-input
            v-model="$v.form.account_digit.$model"
            v-mask="'X'"
            class="form-control form-control-solid h-auto py-5 px-6 rounded"
            :state="validateState('account_digit')"
          />
          <b-form-invalid-feedback>O campo é obrigatório.</b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>

    <!--begin::Action-->
    <div class="form-group d-flex justify-content-center">
      <button
        ref="kt_login_signup_submit"
        type="submit"
        class="btn btn-success font-weight-bold px-12 py-4 my-3 font-size-3 mx-4"
        :disabled="isSubmitting"
        :class="{ 'spinner spinner-light spinner-right': isSubmitting }"
      >
        Salvar conta
      </button>
    </div>
    <!--end::Action-->
  </b-form>
  <!--begin::End Form-->
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],
  data() {
    return {
      isSubmitting: false,
      form: {
        account_type_id: null,
        ecosystem_bank_id: null,
        agency_code: null,
        agency_digit: null,
        account_number: null,
        account_digit: null
      }
    };
  },
  computed: mapGetters(['formBankParams', 'currentCustomer']),
  async mounted() {
    await this.$store.dispatch('GET_BANK_ACCOUNT_PARAMS');
  },
  methods: {
    async onSubmit() {
      this.isSubmitting = true;

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.isSubmitting = false;
        return;
      }

      //SEND REQUEST
      await this.$store
        .dispatch('STORE_BANK_ACCOUNT', this.form)
        .then((response) => {
          this.$swal({
            title: response.message,
            icon: 'success'
          });
          this.isSubmitting = false;
          this.$bvModal.hide('new-account-bank-modal');
          this.$store.dispatch('GET_BANK_ACCOUNT_LIST');
        })
        .catch((error) => {
          this.isSubmitting = false;
          this.$swal({
            title: error,
            icon: 'error'
          });
        });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      var self = this;
      Object.keys(this.form).forEach(function (key) {
        self.form[key] = null;
      });
    }
  },
  validations: {
    form: {
      account_type_id: { required },
      ecosystem_bank_id: { required },
      agency_code: { required },
      agency_digit: { required },
      account_number: { required },
      account_digit: { required }
    }
  }
};
</script>

<style></style>
