<template>
  <div class="text-center busy-loader">
    <lottie-player
      autoplay
      loop
      mode="normal"
      style="height: 100px"
      src="https://assets9.lottiefiles.com/packages/lf20_VsPRP4.json"
    ></lottie-player>
    <h6 class="text-center mt-4 text-muted">{{ message }}</h6>
  </div>
</template>

<script>
export default {
  name: 'BusyTable',
  props: {
    message: {
      type: String,
      default: 'Não existem registros.'
    }
  }
};
</script>

<style></style>
